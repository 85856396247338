export const signOut = async () => {
    /*
    We use encrypted cookie that contains all the data, and we do not store this cookie or data in the 
    backend server, and now we use the useCookie composable to delete session cookie from the browser 
    (see below), there is really no need to hit the /logout endpoint.  But this didn't completely work 
    for some reason (using the useCookie composable to delete the session cookie didn't work).  It 
    didn't sign the user out, or somehow didn't delete the cookie, so we do have to hit the /logout 
    endpoint for now.
    */
    const url = "/logout"
    await logFetchAJAX(url,{});

    const my_cookie = useCookie('MY_V_AUTH', {
        'sameSite': 'lax'
    });
    my_cookie.value = 0;

    const authState = useAuthState();
    authState.value = false;

    /*
    const aYearAgo = new Date();
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
    const current_space_cookie = useCookie('current_space', {
      'sameSite': 'lax',
      'expires': aYearAgo
    });
    current_space_cookie.value = ''
    */
    
    if (typeof(window) != 'undefined') {
        localStorage.removeItem('isAuthenticated')
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('org');
    }

    /*
    The /logout call above does hit the backend server, and does go through the steps to log out the user.
    However, for some reason, the user is still not logged out from the backend, so we have to use 
    frontend code to delete the cookie.  However, this alone didn't work consistently.  I had to change the 
    code inside UserIconTopRight.vue for the "Sign out" link.  The to attribute was using "#".  I also added 
    the @click to @click.prevent.stop, and that didn't work.  So I changed it to @mousedown.prevent.stop, 
    and change the to attribute to "/", and it seems to work consistently now.

    Previously, it didn't work because it navigated to # which is the index page, which cause a request 
    to either perform the search for the home page, or to get the count for the notification bell icon.
    This request send the token which essentially recreate the session in the backend.
    */
    const session_cookie = useCookie('session');  
    my_cookie.value = '';

}
